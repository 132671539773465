import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Input, Button, SwitchLink } from './Modal';
import { FaGoogle, FaFacebookF, FaTwitter, FaApple, FaTelegramPlane } from 'react-icons/fa';

const SocialContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
`;

const IconButton = styled.button`
    background: #f5f5f5;
    color: #333;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 5px;

    &:hover {
        background: #e0e0e0;
    }

    svg {
        width: 24px;
        height: 24px;
    }
`;

const Divider = styled.div`
    margin: 20px 0;
    display: flex;
    align-items: center;
    text-align: center;

    &:before,
    &:after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #ddd;
    }

    &:before {
        margin-right: 10px;
    }

    &:after {
        margin-left: 10px;
    }
`;

const Login = ({ onClose, switchToRegister, switchToForgotPassword }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await fetch('/api/auth/csrf/', {
                    credentials: 'include',
                });
                const data = await response.json();
                const csrfToken = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('csrftoken='))
                    ?.split('=')[1];
                setCsrfToken(csrfToken);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/auth/login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();
            if (!response.ok) {
                setError(data.error || 'Login failed');
            } else {
                console.log('Login successful');
                onClose();
            }
        } catch (error) {
            console.error('Error during login:', error);
            setError('Login failed');
        }
    };

    const handleSocialLogin = (provider) => {
        window.location.href = `/accounts/${provider}/login/`;
    };

    return (
        <Modal title="Login" onClose={onClose}>
            <form onSubmit={handleSubmit}>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button type="submit">Login</Button>
            </form>
            <Divider>or</Divider>
            <SocialContainer>
                <IconButton onClick={() => handleSocialLogin('google')}>
                    <FaGoogle />
                </IconButton>
                <IconButton onClick={() => handleSocialLogin('facebook')}>
                    <FaFacebookF />
                </IconButton>
                <IconButton onClick={() => handleSocialLogin('twitter')}>
                    <FaTwitter />
                </IconButton>
                <IconButton onClick={() => handleSocialLogin('apple')}>
                    <FaApple />
                </IconButton>
                <IconButton onClick={() => handleSocialLogin('telegram')}>
                    <FaTelegramPlane />
                </IconButton>
            </SocialContainer>
            <SwitchLink onClick={switchToRegister}>Don't have an account? Register</SwitchLink>
            <SwitchLink onClick={switchToForgotPassword}>Forgot Password?</SwitchLink>
        </Modal>
    );
};

export default Login;
