// TagItems.js
import React from 'react';
import ItemList from './ItemList';
import { useParams } from 'react-router-dom';
import he from 'he';
import Breadcrumb from './Breadcrumb';

const TagItems = () => {
    const params = useParams();
    const tag = he.decode(params['tag']);

    return<>
      <Breadcrumb field="tag" value={tag} />
      <ItemList field="tag" apiEndpoint={`/api/tag_items/${tag}`} />
      </>
   
};

export default TagItems;
