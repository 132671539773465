const fetchData = async (url, setData) => {
  try {
    const response = await fetch(url, {
      method: 'GET', // or 'POST' etc.
      credentials: 'include', // Important for cookies to be sent and received
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const jsonData = await response.json();
    if (setData) {
      setData(jsonData['data']);
    } else {
      return jsonData['data'];
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export default fetchData;
