import React, { useEffect, useState } from 'react';
import {
  InstagramEmbed,
  TikTokEmbed,
  PinterestEmbed,
  FacebookEmbed,
  XEmbed,
} from 'react-social-media-embed';
import Embed from 'react-embed';

// Helper function to extract Twitter ID from URL
const extractTwitterId = (url) => {
  const match = url.match(/status\/(\d+)/);
  return match ? match[1] : null;
};

const EmbedWrap = ({ category, title, source, time, imageUrl, altText, withTopBorder = true }) => {
  const [embedComponent, setEmbedComponent] = useState(null);

  useEffect(() => {
    let component = null;
    try {
      if (source.includes('tiktok.com')) {
        component = <TikTokEmbed url={source} width="100%" />;
      } else if (source.includes('instagram.com')) {
        component = <InstagramEmbed url={source} width="100%" />;
      } else if (source.includes('twitter.com') || source.includes('x.com')) {
        const twitterId = extractTwitterId(source);
        if (twitterId) {
          component = <XEmbed id={twitterId} width="100%" url={source}/>;
        } else {
          // component = <Embed id={twitterId} url={source} />;

          // console.error('Invalid Twitter URL:', source);
          // throw new Error('Invalid Twitter URL');
        }
      } else if (source.endsWith('.pdf')) {
        component = <Embed url={source} />;
      } else {
        component = <Embed url={source} width="100%"/>;
      }
    } catch (e) {
      console.error('Error:', e);
    }

    setEmbedComponent(component);
  }, [source]);

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-4">
      {embedComponent}
    </div>
  );
};

export default EmbedWrap;


//   try {
  //     if (source.includes('instagram.com')) {
  //       component = <InstagramEmbed url={source} width="100%" />;
  //     } else if (source.includes('youtube.com')) {
  //       component = (
  //         <YouTubeEmbed
  //           url={source}
  //           width="100%"
  //         />
  //       );
  //     } else if (source.includes('x.com') || source.includes('twitter.com')) {
  //       component = <XEmbed url={source} width="100%" />;
  //     } else if (source.includes('tiktok.com')) {
  //       component = <TikTokEmbed url={source} width="100%" />;
  //     } else if (source.includes('pinterest.co')) {
  //       component = <PinterestEmbed url={source} width="100%" />;
  //     } else if (source.includes('facebook.co')) {
  //       component = <FacebookEmbed url={source} width="100%" />;
  //     }
  //   } catch (e) {
  //     console.log('Error:', e);
  //   }

  //   setEmbedComponent(component);
  // }, [source]);

  // useEffect(() => {
  //   if (isYouTubeReady) {
  //     // Perform any actions that require the YouTube player to be ready
  //     console.log('YouTube player is ready');
  //     // Example: player.playVideo();
  //   }
  // }, [isYouTubeReady]);



  // else if (source.includes('facebook.com')) {
  //   component = <FacebookEmbed url={source} width="100%" />;
  // } 

  ///can't display
  // else if (source.includes('pinterest.co')) {
  //   component = <PinterestEmbed url={source} width="100%" />;
  // }