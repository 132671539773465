// Related.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import ItemList from './ItemList';

function Related({ title }) {

  return (
    <>
      <div className="max-w-7xl mx-auto py-1 sm:px-6 lg:px-8">
        <div className="text-gray-600 text-sm uppercase tracking-wide">
          <FontAwesomeIcon icon={faList} className="ml-4" /> Related
        </div>
      </div>
       
     <ItemList apiEndpoint={`/api/related/${title}`}/>
        
    </>
  );
}

export default Related;
