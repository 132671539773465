import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, SwitchLink } from './Modal';
import SuccessModal from './SuccessModal';

const Register = ({ onClose, switchToLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [csrfToken, setCsrfToken] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await fetch('/api/auth/csrf/', {
                    credentials: 'include',
                });
                const data = await response.json();
                const csrfToken = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('csrftoken='))
                    ?.split('=')[1];
                setCsrfToken(csrfToken);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            const response = await fetch('/api/auth/register/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();
            if (!response.ok) {
                setError(data.error || 'Registration failed');
            } else {
                if (data.message === 'User already exists. Please log in.') {
                    setMessage(data.message);
                } else {
                    setMessage('User registered successfully');
                    setShowSuccess(true);
                }
            }
        } catch (error) {
            console.error('Error during registration:', error);
            setError('Registration failed');
        }
    };

    if (showSuccess) {
        return <SuccessModal onClose={onClose} switchToLogin={switchToLogin} />;
    }

    return (
        <Modal title="Register" onClose={onClose}>
            <form onSubmit={handleSubmit}>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {message && <p style={{ color: 'green' }}>{message}</p>}
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button type="submit">Register</Button>
            </form>
            <SwitchLink onClick={switchToLogin}>Already have an account? Login</SwitchLink>
        </Modal>
    );
};

export default Register;
