import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 10px;
`;

const Container = styled.div`
    position: relative;
    background: #fff;
    padding: 40px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    text-align: center;

    @media (max-width: 600px) {
        padding: 20px;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
`;

const Title = styled.h2`
    margin-bottom: 20px;
    color: #333;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
`;

const Button = styled.button`
    width: 100%;
    padding: 10px;
    background: #007bff;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
        background: #0056b3;
    }
`;

const SwitchLink = styled.a`
    display: block;
    margin-top: 20px;
    color: #007bff;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const Modal = ({ title, children, onClose }) => (
    <ModalOverlay>
        <Container>
            <CloseButton onClick={onClose}>
                <FaTimes />
            </CloseButton>
            <Title>{title}</Title>
            {children}
        </Container>
    </ModalOverlay>
);

export { Modal, Input, Button, SwitchLink };
