// Breadcrumb.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCircleChevronLeft, faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import he from 'he';

const Breadcrumb = ({field, value }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-4">
      <div className="text-gray-600 uppercase tracking-wide">
        <FontAwesomeIcon icon={faCircleChevronLeft} className="mr-3 text-lg" onClick={handleBack} />
        <Link to="/">
          <FontAwesomeIcon icon={faHome} className="mr-2 text-lg" />
        </Link>
        <FontAwesomeIcon icon={faGreaterThan} className="mr-2" />
        <Link to={`/${field}/${value}`}>
          {he.decode(value)}
        </Link>
      </div>
    </div>
  );
};

export default Breadcrumb;
