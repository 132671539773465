import React from 'react';
import styled from 'styled-components';
import { Modal, Button } from './Modal'; // Reusing Modal and Button components

const SuccessMessage = styled.p`
    color: green;
    font-size: 1.2em;
`;

const SuccessModal = ({ onClose, switchToLogin }) => {
    return (
        <Modal title="Registration Successful" onClose={onClose}>
            <SuccessMessage>Your account has been created successfully!</SuccessMessage>
            <Button onClick={switchToLogin}>Go to Login</Button>
        </Modal>
    );
};

export default SuccessModal;
