import React, { useState, useEffect, useRef, useCallback } from 'react';
import Card4 from './Card4';
import Card2 from './Card2';
import Card1 from './Card1';
import Card3 from './Card3';
import Card5 from './Card5';
import EmbedWrap from './EmbedWrap';
import ErrorBoundary from './ErrorBoundary';
import fetchData from './dataFetch';

const ItemList = ({ apiEndpoint }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const initialLoad = useRef(true);

  const pullItems = useCallback(async () => {
    if (isLoading || !hasMore) return;

    setIsLoading(true);
    try {
      const api_base = "";
      const data = await fetchData(`${api_base}${apiEndpoint}?s=${skip}`);
      if (data.length === 0) {
        setHasMore(false);
      } else {
        setItems((prevItems) => {
          const newItems = data.filter(
            (newItem) => !prevItems.some((prevItem) => prevItem.id === newItem.id)
          );
          return [...prevItems, ...newItems];
        });
        setSkip((prevSkip) => prevSkip + data.length);
      }
    } catch (error) {
      console.error("Failed to fetch items:", error);
    }
    setIsLoading(false);
  }, [isLoading, hasMore, apiEndpoint, skip]);

  useEffect(() => {
    if (initialLoad.current) {
      pullItems();
      initialLoad.current = false;
    }
  }, [pullItems]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;

      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

      if (scrolledToBottom && !isLoading && hasMore) {
        pullItems();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, hasMore, pullItems]);

  return (
    <div className="max-w-7xl mx-auto py-1 sm:px-6 lg:px-8">
      <div className="px-4 py-1 sm:px-0">
        <div className="rounded-lg h-96">
          {/* {isLoading && <p>Loading...</p>} */}
          {items.map((item) => {
            switch (item.show_type) {
              case 'card1':
                return <Card1 key={item.id} {...item} />;
              case 'card2':
                return <Card2 key={item.id} {...item} />;
              case 'card3':
                return <Card3 key={item.id} {...item} />;
              case 'card4':
                return <Card4 key={item.id} {...item} />;
              // case 'card5':
              //   return <Card5 key={item.id} {...item} />;
              case 'embed':
                return (
                  <ErrorBoundary key={item.id}>
                    <EmbedWrap {...item} />
                  </ErrorBoundary>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default ItemList;
