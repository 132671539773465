import React from 'react';
import { Link } from 'react-router-dom';
import he from 'he';
import Image from './Image'

// FeedBlock
function FeedBlock({ category, title, time, imageUrl, altText }) {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-4">
      <Link to={`/article/${he.decode(category)}/${encodeURIComponent(title)}`}>
        <Image src={imageUrl} alt={altText} className="w-full h-48 object-cover object-center" />
      </Link>
      <div className="p-4">
        
        <Link to={`/article/${he.decode(category)}/${encodeURIComponent(title)}`}>
          <h3 className="font-bold text-gray-900 text-md line-clamp-2 overflow-hidden">{title}</h3>
        </Link>
        <div className="text-gray-700 text-sm mt-2 flex items-center">
          <p>{time}</p>
          <span className="mx-2">·</span>
          <Link to={`/category/${he.decode(category)}`} className="text-blue-500 hover:underline">
            {he.decode(category)}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FeedBlock;
