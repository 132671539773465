import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faUser } from '@fortawesome/free-solid-svg-icons';
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';

function Header() {
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('login');

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const switchToRegister = () => {
        setModalType('register');
    };

    const switchToLogin = () => {
        setModalType('login');
    };

    const switchToForgotPassword = () => {
        setModalType('forgotPassword');
    };

    return (
        <div className="bg-white shadow mb-2">
            <div className="max-w-7xl mx-auto text-lg py-2 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                <div className="flex items-center">
                    <FontAwesomeIcon className="fas fa-bars mr-2 font-semibold text-blue-600" icon={faLightbulb} />
                    <Link to="/">
                        <span className="text-lg font-semibold font-mono text-blue-600">HappyKnow</span>
                    </Link>
                </div>
                <div className="flex items-center">
                    <FontAwesomeIcon className="fas fa-user text-gray-600 mr-4" icon={faUser} />

                    {/* <button onClick={toggleModal} className="text-gray-600 hover:text-gray-900">
                        <FontAwesomeIcon className="fas fa-user text-gray-600 mr-4" icon={faUser} />
                    </button> */}
                </div>
            </div>
            {showModal && modalType === 'login' && (
                <Login onClose={toggleModal} switchToRegister={switchToRegister} switchToForgotPassword={switchToForgotPassword} />
            )}
            {showModal && modalType === 'register' && (
                <Register onClose={toggleModal} switchToLogin={switchToLogin} />
            )}
            {showModal && modalType === 'forgotPassword' && (
                <ForgotPassword onClose={toggleModal} switchToLogin={switchToLogin} />
            )}
        </div>
    );
}

export default Header;
