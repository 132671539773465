// PlaceItems.js
import React from 'react';
import ItemList from './ItemList';
import { useParams } from 'react-router-dom';
import he from 'he';
import Breadcrumb from './Breadcrumb';

const PlaceItems = () => {
    const params = useParams();
    const place = he.decode(params['place']);

    return<>
      <Breadcrumb field="place" value={place} />
      <ItemList field="place" apiEndpoint={`/api/place/${place}`} />
      </>
   
};

export default PlaceItems;
