// CategoryItems.js
import React from 'react';
import ItemList from './ItemList';
import { useParams } from 'react-router-dom';
import he from 'he';
import Breadcrumb from './Breadcrumb';

// CategoryItems

const CategoryItems = () => {
    const params = useParams();
    const category = he.decode(params['category']);

    return <>
            <Breadcrumb field="category" value={category} />
            <ItemList apiEndpoint={`/api/category_items/${he.decode(category)}`} />;
        </>
};

export default CategoryItems;
