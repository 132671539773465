import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import fetchData from './dataFetch';
import ScrollToTop from './ScrollToTop';
import Markdown from './Markdown';
import he from 'he';
import { Helmet } from 'react-helmet';
import Image from './Image'

function getFirstParagraph(text) {
  const paragraphs = text.split('\n');
  for (let paragraph of paragraphs) {
      if (paragraph.trim()) {  // Check if the paragraph is not empty
          return paragraph.trim();
      }
  }
  return null;
}

function Article({ title }) {
  const [data, setData] = useState({
    title: '',
    image: '',
    keyword: '',
    source: '',
    time: '',
    text: '',
    tags: [],
    entities:[],
    places:[],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataAsync = async () => {
      const result = await fetchData(`/api/article/${encodeURIComponent(title)}`);
      if (result && Object.keys(result).length > 0) {
        setData(result);
      } else {
        navigate('/');
      }
    };

    fetchDataAsync();
  }, [title]);

  const baseURL = window.location.origin; // Get the base URL
  const shareUrl = window.location.href;
  const shareTitle = data.title;

  const handleShare = (platform) => {
    let url = '';
    switch (platform) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareTitle)}`;
        break;
      case 'reddit':
        url = `https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareTitle)}`;
        break;
      case 'whatsapp':
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareTitle)}%20${encodeURIComponent(shareUrl)}`;
        break;
      case 'email':
        url = `mailto:?subject=${encodeURIComponent(shareTitle)}&body=${encodeURIComponent(shareTitle)}%20${encodeURIComponent(shareUrl)}`;
        break;
      default:
        break;
    }
    window.open(url, '_blank');
  };

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <meta property="og:title" content={shareTitle} />
        <meta name="description" content={getFirstParagraph(data.text)} />
        <meta property="og:description" content={getFirstParagraph(data.text)} />
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="twitter:title" content={shareTitle} />
        <meta property="twitter:description" content={getFirstParagraph(data.text)} />
        <meta property="twitter:image" content={`${baseURL}/${data.image}`} />
        <meta property="og:image" content={`${baseURL}/${data.image}`} />
        <meta property="twitter:card" content="summary_large_image" />
        {/* <meta property="twitter:site" content="@politico" />
        <meta property="twitter:creator" content="@politico" /> */}
      </Helmet>

      <h1 className="text-xl font-bold mb-3">
        {data.title}
      </h1>
      <div className="flex space-x-2 mb-6">
      <button onClick={() => handleShare('twitter')} className="text-blue-400"><i className="fab fa-twitter"></i></button>
        <button onClick={() => handleShare('facebook')} className="text-blue-600"><i className="fab fa-facebook-f"></i></button>
        <button onClick={() => handleShare('reddit')} className="text-orange-600"><i className="fab fa-reddit-alien"></i></button>
        <button onClick={() => handleShare('whatsapp')} className="text-green-600"><i className="fab fa-whatsapp"></i></button>
        <button onClick={() => handleShare('email')} className="text-gray-600"><i className="fas fa-envelope"></i></button>
      </div>
      {data.image && <Image src={data.image} alt={data.keyword} className="w-full mb-4" />}
      <div className="text-lg mb-2">
        {data.source && (
          <a href={data.source} target="_blank" rel="noopener noreferrer">{data.source}</a>
        )}
      </div>
      <div className="text-gray-500 text-sm mb-6">
        {data.time}
      </div>
      <div className="mb-4">
        <Markdown markdownText={data.text} entities={data.entities} places={data.places}/>
      </div>
      
      {data.tags && data.tags.length > 0 && (
        <div className="flex flex-wrap font-bold text-gray-600 text-sm uppercase">
          {data.tags.map((tag, index) => (
            <Link key={index} to={`/tag/${tag}`} className="mr-2">
              #{he.decode(tag)}
            </Link>
          ))}
        </div>
      )}
    </>
  );
}

export default Article;
