import React from 'react';
import { Link } from 'react-router-dom';
import he from 'he';
import Image from './Image'

// item-left

function FeedCardWithIcon({ category, title, time, imageUrl, altText }) {
  return (
    <div className="border border-gray-200 rounded-lg shadow-lg mb-4 flex">
      <div className="flex-shrink-0 flex items-stretch">
        <Link to={`/article/${he.decode(category)}/${encodeURIComponent(title)}`} className="flex items-stretch">
          <Image className="w-28 h-full object-cover rounded-l-lg" src={imageUrl} alt={altText} />
        </Link>
      </div>
      <div className="p-4 flex flex-col justify-between flex-grow">
        <div>
          <Link to={`/article/${he.decode(category)}/${encodeURIComponent(title)}`}>
            <h3 className="font-bold text-gray-900 text-md mt-2 line-clamp-2 overflow-hidden">{title}</h3>
          </Link>
        </div>
        <div className="text-gray-700 text-sm mt-2 flex items-center">
          <p>{time}</p>
          <span className="mx-2">·</span>
          <Link to={`/category/${encodeURIComponent(category)}`} className="text-blue-500 hover:underline">
            {he.decode(category)}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FeedCardWithIcon;
