import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import he from 'he';

function FeedCardWithBackground({ category, title, time, imageUrl, altText }) {
  const navigate = useNavigate();

  const handleBackgroundClick = () => {
    navigate(`/article/${he.decode(category)}/${encodeURIComponent(title)}`);
  };

  return (
    <div
      className="relative bg-cover bg-center h-64 w-full rounded-lg shadow-lg mb-4 cursor-pointer"
      style={{ backgroundImage: `url(${imageUrl})`, zIndex: 1 }}
      onClick={handleBackgroundClick}
    >
      <div className="absolute inset-0 bg-black opacity-30 rounded-lg"></div>
      <div className="relative p-4 flex flex-col justify-between h-full">
        <div>
          <Link
            to={`/category/${encodeURIComponent(category)}`}
            className="text-white text-sm font-medium z-10"
            onClick={(e) => e.stopPropagation()}
          >
            {he.decode(category)}
          </Link>
          <h3 className="text-white font-bold text-md mt-2 z-10 line-clamp-2 overflow-hidden">{title}</h3>
        </div>
        <div className="text-white text-sm mt-2 z-10">
          {time}
        </div>
      </div>
    </div>
  );
}

export default FeedCardWithBackground;
