import React, { useState } from 'react';
import { Modal, Input, Button, SwitchLink } from './Modal';

const ForgotPassword = ({ onClose, switchToLogin }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/auth/password/reset/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                const data = await response.json();
                setError(data.non_field_errors ? data.non_field_errors.join(', ') : 'Password reset request failed');
            } else {
                console.log('Password reset request successful');
                onClose();
            }
        } catch (error) {
            console.error('Error during password reset request:', error);
            setError('Password reset request failed');
        }
    };

    return (
        <Modal title="Forgot Password" onClose={onClose}>
            <form onSubmit={handleSubmit}>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button type="submit">Reset Password</Button>
            </form>
            <SwitchLink onClick={switchToLogin}>Remembered your password? Login</SwitchLink>
        </Modal>
    );
};

export default ForgotPassword;
