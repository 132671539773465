import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Header from './components/Header';
import Detail from './components/Detail';
import CategoryItems from './components/CategoryItems';
import TagItems from './components/TagItems';
import EntityItems from './components/EntityItems'
import PlaceItems from './components/PlaceItems'
import { logPageView } from './analytics';
import Login from './components/Login';

function App() {
  return (
    <div className="App">
      <div className="min-h-screen">
        <Router>
          <Header />
          <AppRoutes />
        </Router>
      </div>
    </div>
  );
}

function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    if (window.location.hostname === 'www.happyknow.com') {
      logPageView();
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Home />} />
      <Route path="/article/:title" element={<Detail />} />
      <Route path="/article/:category/:title" element={<Detail />} />
      <Route path="/category/:category" element={<CategoryItems />} />
      <Route path="/tag/:tag" element={<TagItems />} />
      <Route path="/entity/:entity" element={<EntityItems />} />
      <Route path="/place/:place" element={<PlaceItems />} />

    </Routes>
  );
}

export default App;

