import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import Image from './Image'

export const replaceEntitiesWithLinks = (text, entities, places) => {
  let updatedText = text;
  if (entities != null && entities.length > 0){
    entities.forEach(entity => {
      const encodedEntity = encodeURIComponent(entity);
      const entityLink = `[${entity}](/entity/${encodedEntity})`;
      const entityRegex = new RegExp(`\\b${entity}\\b`, 'g');
      updatedText = updatedText.replace(entityRegex, entityLink);
    });
  }

  if (places != null && places.length > 0){
    places.forEach(place => {
      const encodedPlace = encodeURIComponent(place);
      const placeLink = `[${place}](/place/${encodedPlace})`;
      const placeRegex = new RegExp(`\\b${place}\\b`, 'g');
      updatedText = updatedText.replace(placeRegex, placeLink);
    });
  }
  return updatedText;
};

const Markdown = ({ markdownText, entities, places}) => {
  const updatedMarkdownText = replaceEntitiesWithLinks(markdownText, entities, places);

  // Define custom components for rendering specific Markdown elements
  const components = {
    // Customizing how links are rendered
    a: ({ node, ...props }) => <Link to={props.href} class={ 'text-blue-500 hover:underline' }>{props.children}</Link>,
    h1: ({ node, ...props }) => <h1 {...props}>{props.children}</h1>,
    p: ({ node, ...props }) => <p style={{ marginBottom: '1em' }} {...props}>{props.children}</p>,
    strong: ({ node, ...props }) => <strong style={{ fontWeight: 'bold' }}>{props.children}</strong>,
    img: ({ node, ...props }) => <Image {...props} />,

  };

  return (
    <div>
      <ReactMarkdown components={components}>
        {updatedMarkdownText}
      </ReactMarkdown>
    </div>
  );
}

export default Markdown;
