// Detail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import Article from './Article';
import Related from './Related';
import Breadcrumb from './Breadcrumb';

function Detail() {
  let { title, category } = useParams();

  if (!category){
    category = "unknow"
  }
    
  if (!title) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Breadcrumb field="category" value={category} />
      <div className="p-4">
        <Article title={title}/>
        <hr className="mt-4"  />
      </div>
      <Related title={title} />
    </>
  );
}

export default Detail;
