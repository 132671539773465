import React from 'react';
import ItemList from './ItemList';
import { Helmet } from 'react-helmet';

function Home() {
  return (
  <>
          <Helmet>
            <meta property="og:title" content="happyknow" />
            <meta name="description" content="Welcome to HappyKnow.com – your go-to destination for a world of information at your fingertips! Whether you're looking for the latest news, trending topics, or insightful articles, or just want to explore something new, HappyKnow.com has it all. From technology and entertainment to health and lifestyle, we cover every aspect of your interests. Stay informed, entertained, and inspired with HappyKnow.com – where knowledge meets happiness." />
            <meta property="og:description" content="Welcome to HappyKnow.com – your go-to destination for a world of information at your fingertips! Whether you're looking for the latest news, trending topics, or insightful articles, or just want to explore something new, HappyKnow.com has it all. From technology and entertainment to health and lifestyle, we cover every aspect of your interests. Stay informed, entertained, and inspired with HappyKnow.com – where knowledge meets happiness." />
          </Helmet>
          <ItemList apiEndpoint="/api/home/" />
  </>
  );
}

export default Home;
