// EntityItems.js
import React from 'react';
import ItemList from './ItemList';
import { useParams } from 'react-router-dom';
import he from 'he';
import Breadcrumb from './Breadcrumb';

const EntityItems = () => {
    const params = useParams();
    const entity = he.decode(params['entity']);

    return<>
      <Breadcrumb field="entity" value={entity} />
      <ItemList field="entity" apiEndpoint={`/api/entity/${entity}`} />
      </>
   
};

export default EntityItems;
