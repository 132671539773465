import React, { useState } from 'react';

const Image = ({ src, alt, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(true);

  const handleError = () => {
    setIsLoaded(false);
  };

  return isLoaded ? <img src={src} alt={alt} onError={handleError} {...props} /> : null;
};

export default Image;
